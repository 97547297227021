import React from "react"
import { StyledHeader } from "../Components/styled-components/Header.style"
import { StyledSubheader } from "../Components/styled-components/Subheader.style"
import { ThemeProvider } from "styled-components"
import { GlobalStyle, theme } from "../utils/theme"
const NotFoundPage = () => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <main>
          <StyledHeader>Nie ma takiej strony</StyledHeader>
          <StyledSubheader>
            <a href="/">Wróć do strony głównej</a>
          </StyledSubheader>
        </main>
      </ThemeProvider>
    </>
  )
}

export default NotFoundPage
